export default [
    {
        path: "/veiculos",
        name: "veiculos",
        component: () => import("@/views/area-mention/veiculos/index.vue"),
        meta: {
          title: 'Veiculos',
          authRequired: true,
          roles: []
        },
    },
    {
      path: "/veiculos/:id",
      name: "veiculos-view",
      component: () => import("@/views/area-mention/veiculos/form.vue"),
      meta: {
        title: 'Veiculos',
        authRequired: true,
        roles: []
      },
  },
];