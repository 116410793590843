<template>
    <div v-if="props.nome || props.foto" class="text-center">
        <img v-if="props.foto" :src="props.foto" class="custom-image-user-img m-auto" :title="props.nome" />
        <div v-else
            :title="props.nome"
            class="bg-light rounded-circle border custom-image-user-navname d-flex align-items-center justify-content-center m-auto">
            <div>{{ getIniciais(props.nome) }}</div>
        </div>
    </div>
</template>
<script setup>
import { getIniciais } from '@/utils/functions';
import { defineProps } from 'vue'
const props = defineProps({
    nome: { type: String },
    foto: { type: String }
})
</script>